import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CloseDrawer, SetCurrentDrawer } from './drawer.actions';
import { DynamicItem } from './drawer.types';

export class DrawerStateModel {
  currentDrawer: DynamicItem;
}

@State<DrawerStateModel>({
  name: 'Drawer',
  defaults: new DrawerStateModel(),
})
@Injectable()
export class DrawerState {
  @Selector()
  static currentDrawer(state: DrawerStateModel): DynamicItem {
    return state.currentDrawer;
  }
  
  @Selector()
  static drawerIsOpen(state: DrawerStateModel): boolean {
    return (
      state.currentDrawer !== null &&
      state.currentDrawer !== undefined &&
      Object.keys(state.currentDrawer).length > 0
    );
  }

  @Action(SetCurrentDrawer)
  setCurrentDrawer(
    ctx: StateContext<DrawerStateModel>,
    { currentDrawer }: SetCurrentDrawer,
  ) {
    ctx.patchState({ currentDrawer });
  }

  @Action(CloseDrawer)
  closeDrawer(ctx: StateContext<DrawerStateModel>) {
    ctx.patchState({ currentDrawer: null });
  }
}
